import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Routing from './Routing/Routing';
import { connect } from 'react-redux';
import { SET_DEVICE_DIMENSIONS } from './Constants/actionTypes';
import Subscribe from './Subscribe.js';

const mapStateToProps = state => ({
  device_settings : state.settings.device
});

const mapDispatchToProps = dispatch => ({
  setDeviceDimensions : (payload) => dispatch({ type: SET_DEVICE_DIMENSIONS , payload })
});

const AppWrapper = styled.div`
  display : flex;
  flex-direction : column;
  min-height : 100vh;
  height : 100%;
`;

class App extends Component {

  constructor(props){
    super(props);
    this.props.setDeviceDimensions({ "width" : window.innerWidth });
  }

  render() {
    const { device_settings } = this.props;
    const { is_small_device, is_medium_device } = device_settings;
    return (
      <AppWrapper>
        <Fragment>
          <Routing />
        </Fragment>
        {
          is_small_device || is_medium_device ? <Subscribe /> : null
        }
      </AppWrapper>
    )
  }
}

export default connect(mapStateToProps , mapDispatchToProps)(App);
