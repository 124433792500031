
// define different device sizes here.
export const SCREEN_SIZE = {
    mobile_s: 320,
    mobile_m: 375,
    mobile_l: 425,
    tablet: 768,
    laptop: 1024,
    laptop_l: 1440,
    desktop: 2560
}

// define media queries for different devices.
export const MEDIA_QUERIES = {
    mobile_s: `(min-width: ${SCREEN_SIZE.mobile_s}px)`,
    mobile_m: `(min-width: ${SCREEN_SIZE.mobile_m}px)`,
    mobile_l: `(min-width: ${SCREEN_SIZE.mobile_l}px)`,
    tablet: `(min-width: ${SCREEN_SIZE.tablet}px)`,
    laptop: `(min-width: ${SCREEN_SIZE.laptop}px)`,
    laptop_l: `(min-width: ${SCREEN_SIZE.laptop_l}px)`,
    desktop: `(min-width: ${SCREEN_SIZE.desktop}px)`
};