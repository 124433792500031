import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import styled from 'styled-components';

import { SET_CATEGORY } from './Constants/actionTypes';
import { MEDIA_QUERIES } from './Constants/device.js';
import CATEGORIES from './Data/categories.js';

const mapStateToProps = (state: any) => ({ category: state.category })

const mapDispatchToProps = (dispatch: any) => ({
    setCategory : (payload: string) => dispatch({ type: SET_CATEGORY, payload })
});

const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    tokenize: true,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
      "name"
    ]
};

const Container = styled('div')<{width: string}>`
    background-color: whitesmoke;
    width: ${props => props.width};
    align-items: center;
    display: flex;
    flex-direction: column;

    display: block;
    overflow: auto;
`;

const InputContainer = styled.div`
    justify-content: flex-start;
    width: 100%;
    position: sticky;
`

const CustomInput = styled.input`
    font-size: 16px;
    width: 100%;
    padding: 8px 14px;
    height: 45px;

    :focus {
        outline : none;
    }

    @media ${MEDIA_QUERIES.laptop}{
        border: 1px solid whitesmoke;
        box-sizing: border-box;
    }
`;

const ItemsContainer = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y:scroll;
    height: ${window.innerHeight-45}px;
`;

const Item = styled('div')<{selected: boolean}>`
    min-height: 30px;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid #e8e8e8;

    background-color : ${props => props.selected ? '#FFF8CD' : 'none'};
    :hover {
        background-color: #FFF8CD;
    }
`;

const ItemName = styled.div`
    padding-left: 15px;
`;

interface Props {}

interface State {
    search_term: string,
    items: any,
    searched_items: any
};

class LeftBar extends Component<any, State> {

    state: State = {
        search_term : "",
        items: CATEGORIES,
        searched_items: CATEGORIES 
    }

    onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const search_term = event.target.value;
        const items = this.state.items;

        const fuse_instance = new Fuse(items, fuseOptions);

        let searched_items = fuse_instance.search(search_term);

        if (search_term.length === 0){
            searched_items = items;
        }

        this.setState({
            search_term,
            searched_items
		});
    }

    handleOnClick = (category_id: string) => {
        this.props.setCategory(category_id);
    }

    render() {
        const { search_term, searched_items } : State = this.state;
        const { width, category } = this.props;

        return (
            <Container width={width}>
                <InputContainer>
                    <CustomInput placeholder="Search Category" value = {search_term} onChange={this.onSearch} />
                </InputContainer>
                <ItemsContainer>
                {
                    searched_items.map(({ name, image_url} : { name:string, image_url:string}, index: number) => {
                        const category_id = name.toLowerCase().replace(/ |\//g, "_");

                        return (
                        <Item selected={category_id === category} key={ index } onClick={ () => { this.handleOnClick(category_id) } }>
                            <img src={image_url} width="20px" height="20px" alt={image_url} />
                            <ItemName>{ name }</ItemName>
                        </Item>
                        )
                    })
                }
                </ItemsContainer>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);