import { SET_DEVICE_DIMENSIONS } from '../Constants/actionTypes';
import { SCREEN_SIZE } from '../Constants/device.js';
const cloneDeep = require('lodash/lang').cloneDeep;

interface Device {
  width: number;
  is_small_device: boolean;
  is_medium_device: boolean;
  is_large_device: boolean;
}

interface Settings {
  device: Readonly<Device>;
}

const defaultState: Readonly<Settings> = {
  device: {
    width: 0,
    is_small_device: false,
    is_medium_device: false,
    is_large_device: false
  }
}

export default( state: Readonly<Settings> = defaultState, action: any ) => {
    switch(action.type){
      case SET_DEVICE_DIMENSIONS: {
        const { width }: { width: number } = action.payload;
        const { tablet, laptop }: { tablet: number, laptop: number} = SCREEN_SIZE;

        // This includes : mobile_s, mobile_m, mobile_l;
        const is_small_device: boolean = (width < tablet);
        // This includes : tablet;
        const is_medium_device: boolean = (width >= tablet && width < laptop);
        // This includes : laptop, laptop_l, desktop;
        const is_large_device: boolean = (width >= laptop);
        
        const device: Readonly<Device> = {
          width,
          is_small_device,
          is_medium_device,
          is_large_device
        };

        let clonedState = cloneDeep(state);
        clonedState["device"] = device;
        return clonedState
      }

      default:
        return state
    }
}