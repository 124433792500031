import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from '../HomePage.js';
import Explorer from '../Explorer.js';

const Routing = () =>  (
    <Router>
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/c/:category_id" component={Explorer}/>
        </Switch>
    </Router>
)

export default Routing;