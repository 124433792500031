import React from "react";

function MessageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 16" style={{paddingRight: "10px", paddingLeft: "5px"}}>
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#8795A1" transform="translate(-177 -490)">
          <g transform="translate(148 490)">
            <g transform="translate(29)">
              <path d="M18.542.178H1.653C.917.178.32.775.32 1.51v12.445c0 .736.597 1.333 1.333 1.333h16.89c.736 0 1.333-.597 1.333-1.333V1.51c0-.736-.597-1.333-1.334-1.333zm0 12.835l-3.529-3.529a.667.667 0 00-.942.943l3.529 3.529H2.596l3.528-3.53a.667.667 0 00-.942-.942l-3.529 3.53V4.457l8 6.03a.667.667 0 00.8 0l8.08-6.035.01 8.56zm0-11.502v1.28l-8.475 6.333-8.414-6.333v-1.28h16.89z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MessageIcon;
