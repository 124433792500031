import { SET_CATEGORY } from '../Constants/actionTypes';

const defaultState: string = "infinite_scroll"

export default( state:string = defaultState, action: any ) => {
    switch(action.type){
        case SET_CATEGORY : {
            return action.payload
        }

        default:
            return state
    }
}