import React, { Component } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import CATEGORIES from './Data/categories.js';
import { MEDIA_QUERIES } from './Constants/device.js';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  tokenize: true,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "name"
  ]
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 30px;
`;

const CategoryContainer = styled.div`
  width: 150px;
  height: 150px;
  background-color: #FFF8CD;
  cursor: pointer;
  border-radius: 10px;
  position: relative;

  transform: translate3d(0px, 0px, 0px);

  :hover {
    transform: translate3d(0px, -6px, 0px);
    transition-timing-function: ease-in-out;
    transition: transform 500ms;

    background-color: yellow;
  }
  
  margin: 10px;
  padding: 10px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
`;

const CustomLink = styled.a`
  text-decoration: none !important;
`;

const CustomImage = styled.img`
  width: 50px;
  height: 50px;
`;

const Meta = styled.div`
`;

const Name = styled.div`
  color: black;
  text-decoration: none;
`;

const Count = styled.div`
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;

const Mast = styled.div`
    width: 100%;
    background-color: rgba(255, 248, 205, 0.5);
    margin-bottom: 20px;
    height: 280px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px 20px;
    justify-content: space-evenly;

    background: #fff8cd;
    background: -webkit-linear-gradient(to right, #ffffff, #fff8cd);
    background: linear-gradient(to left, #ffffff, #fff8cd);

    @media ${MEDIA_QUERIES.tablet}{
        height: 340px;
    }

    @media ${MEDIA_QUERIES.laptop}{
      background: #fffc00;
      background: -webkit-linear-gradient(to right, #ffffff, #fffc00);
      background: linear-gradient(to left, #ffffff, #fffc00);
    }
`;

const Brand = styled.div`
    display: flex;
    align-items: center;
`;

const BrandImage = styled.img`
    width: 100px;
    height: 100px;
`;

const BrandName = styled.div`
    font-size: 36px;
    font-weight: 500;
    padding: 0px 20px;
`;

const CustomInput = styled.input`
    width: 90%;
    padding: 6px 12px;
    font-size: 15px;

    border: 1px solid whitesmoke;
    border-radius: 10px;

    :focus {
        outline: none;
    }

    @media ${MEDIA_QUERIES.tablet}{
        width: 400px;
        font-size: 16px;
    }

    @media ${MEDIA_QUERIES.tablet}{
      width: 400px;
      font-size: 18px;
    }
`;

const Tagline = styled.div`
    font-size: 18px;
    padding: 10px 0px;
    font-weight: 400;

    @media ${MEDIA_QUERIES.tablet}{
        font-size: 20px;
        padding: 0px;
    }

    @media ${MEDIA_QUERIES.laptop}{
      font-size: 22px;
      padding: 0px;
    }
`;

const SectionHeading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid whitesmoke;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0px 0px 20px 0px;

  @media ${MEDIA_QUERIES.laptop}{
    font-size: 22px;
    margin-bottom: 10px;
    padding: 10px 0px 20px 0px;
  }
`;

class Explorer extends Component {

  constructor(props){
    super(props);

    this.state = {
      search_term : "",
      items: CATEGORIES,
      searched_items: CATEGORIES 
    }
  }

  onSearch = (event) => {
    const search_term = event.target.value;
    const items = this.state.items;

    const fuse_instance = new Fuse(items, fuseOptions);

    let searched_items = fuse_instance.search(search_term);

    if (search_term.length === 0){
        searched_items = items;
    }

    this.setState({
        search_term,
        searched_items
    });
  }

  render() {
    const { search_term, searched_items } = this.state;
    return (
      <Container>
        <Mast>
            <Brand>
                <BrandImage src="https://i.ibb.co/M7z8qwb/favicon.png" />
                <BrandName>Lego</BrandName>
            </Brand>
            <Tagline>Explore React Components for your Project</Tagline>
            <CustomInput placeholder="Search Category" value = {search_term} onChange={this.onSearch} />
        </Mast>
        <SectionHeading>
          Categories
        </SectionHeading>
        {
          searched_items.map(({ name, items, image_url = "https://www.gstatic.com/images/branding/product/1x/sheets_512dp.png" }, index) => {
            const link = name.toLowerCase().replace(/ |\//g, "_");
            return (
              <CustomLink href={`/c/${link}`} key={index}>
                <CategoryContainer>
                  <CustomImage src={image_url} />
                  <Meta>
                    <Name>{ name }</Name>
                    <Count>{ ` ( ${items.length} )` }</Count>
                  </Meta>
                </CategoryContainer>
              </CustomLink>
            )
          })
        }
      </Container>
    );
  }
}

export default Explorer;