import React from 'react';
import styled from 'styled-components';
import COMPONENTS_DATA from './Data/components_data.js';
import { MEDIA_QUERIES } from './Constants/device.js';

import { Icon } from '@iconify/react'; 
import githubFilled from '@iconify/icons-ant-design/github-filled';
import bookOpen from '@iconify/icons-et/book-open';
import presentationIcon from '@iconify/icons-et/presentation';
const Moment = require('moment');

const MetaContainer = styled.div`
    display: flex;
    max-width: 480px;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 0px 0px 10px;
    min-height: 40px;

    @media ${MEDIA_QUERIES.tablet}{
        padding: 0px 0px 0px 15px;
        min-height: 66px;
        max-width: 480px;
    }
`;

const Name = styled.a`
    font-weight: 400;
    text-decoration: none;
    color: black;
    font-size: 15px;
    white-space: initial;

    @media ${MEDIA_QUERIES.tablet}{
        font-size: 16px;
    }

    @media ${MEDIA_QUERIES.laptop}{
        font-size: 18px;
        font-weight: 500;
    }
`;

const Description = styled.div`
    color: grey;
    font-size: 13px;
    white-space: initial;
    display:none;

    @media ${MEDIA_QUERIES.laptop}{
        display: block;
    }
`;

const LinksContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
`;

const IconContainer = styled.a`
    margin-bottom: 6px;
    color: black !important;
    background-color: white;
    padding: 5px;
    border-radius: ${props => props.isRounded ? '100px' : '0px'};
    border: 1px solid black;
    display: flex;
`;

const SimpleCellValue = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: none !important;
    font-size: 14px;
    font-weight: 400;

    @media ${MEDIA_QUERIES.tablet}{
        font-size: 15px;
    }

    @media ${MEDIA_QUERIES.laptop}{
        font-size: 15px;
        font-weight: 500;
    }
`;

const HeaderCell = styled.div`
    color: grey;
    font-size: 15px;
    white-space: initial;
`

function NumberFormatter(labelValue) 
  {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + " B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + " M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + " K"

    : Math.abs(Number(labelValue));
}

const getColumns = (device_settings) => { 
    const { is_small_device, is_medium_device, is_large_device } = device_settings;
    return [
        {
            id : 'nameId',
            Header: props => <HeaderCell>Name</HeaderCell>, // Custom header components!
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.name
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <MetaContainer>
                        <Name href={item_data.html_url} target="_blank">{ item_data.name }</Name>
                        <Description>{ item_data.description }</Description>
                    </MetaContainer>
                )
            },
            width: is_small_device || is_medium_device ? 100 : 400
        },
        {
            id : 'linksId',
            Header: props => <HeaderCell>Links</HeaderCell>, // Custom header components!
            accessor: d => d,
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <LinksContainer>
                        <IconContainer href={item_data.html_url} target="_blank" isRounded={true}>
                            <Icon icon={githubFilled} style={{ fontSize : "16px" }} />
                        </IconContainer>
                        {
                            item_data.demo_url ? (
                                <IconContainer href={item_data.demo_url} target="_blank" isRounded={false}>
                                    <Icon icon={bookOpen} style={{ fontSize : "13px" }} />
                                </IconContainer>
                            )
                            :
                            null
                        }
                        {
                            item_data.documentation_url ? (
                                <IconContainer href={item_data.documentation_url} target="_blank" isRounded={false}>
                                    <Icon icon={presentationIcon} style={{ fontSize : "15px" }} />
                                </IconContainer>
                            )
                            :
                            null
                        }
                    </LinksContainer>
                )
            },
            width: is_small_device ? 50 : 60
        },
        {
            id: 'stars',
            Header: props => <HeaderCell>Stars</HeaderCell>,
            // Component human readable id
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.stargazers_count
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{NumberFormatter(parseInt(item_data.stargazers_count))}</SimpleCellValue>
                )
            },
            width: is_small_device ? 60 : 90
        }, 
        {
            Header: props => <HeaderCell>Size (Minified)</HeaderCell>,
            id: 'bundle_size',
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.size
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{(parseInt(item_data.size)/1024).toFixed(1)} kB</SimpleCellValue>
                )
            },
            width: is_small_device ? 100 : 140
        }, 
        {
            Header: props => <HeaderCell>Size (Gzipped)</HeaderCell>,
            id: 'bundle_size_gzip',
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.gzip
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{(parseInt(item_data.gzip)/1024).toFixed(1)} kB</SimpleCellValue>
                )
            },
            width: is_small_device ? 100 : 140
        },
        {
            Header: props => <HeaderCell>Age</HeaderCell>,
            id: 'age',
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                
                var current_time = Moment(new Date()); //todays date
                var repo_birth = Moment(item_data.created_at); // another date
                var age = Moment.duration(current_time.diff(repo_birth));
                return age
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{Moment().from(item_data.created_at, true)}</SimpleCellValue>
                )
            },
            width: is_small_device ? 60 : 90
        },
        {
            Header: props => <HeaderCell>Forks</HeaderCell>, // Custom header components!
            id: 'forks',
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.forks
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{NumberFormatter(parseInt(item_data.forks))}</SimpleCellValue>
                )
            },
            width: is_small_device ? 60 : 90
        },
        {
            Header: props => <HeaderCell>Downloads</HeaderCell>, // Custom header components!
            id: 'downloads',
            accessor: (component_hid) => { 
                const item_data = COMPONENTS_DATA[component_hid];
                return item_data.downloads
            },
            Cell: (props) => {
                const original_data = props.original;
                const item_data = COMPONENTS_DATA[original_data];
                return (
                    <SimpleCellValue>{NumberFormatter(parseInt(item_data.downloads))}</SimpleCellValue>
                )
            },
            width: is_small_device ? 100 : 140
        }
    ]
}

export default getColumns