import React, { Component } from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table-v6';
import { connect } from 'react-redux';
import 'react-table-v6/react-table.css';
import getColumns from './Columns.js';
import Subscribe from './Subscribe.js';

import CATEGORY_ITEMS from './Data/category_items.js';
import ITEMS_DATA from './Data/items.js';
import { MEDIA_QUERIES } from './Constants/device.js';

const Container = styled.div`
    width: ${props => props.width};
    display: block;
    overflow: auto;

    @media ${MEDIA_QUERIES.tablet}{
        padding: 0px 10px 0px 20px;
    }

    @media ${MEDIA_QUERIES.laptop}{
        padding: 0px 30px 0px 30px;
    }
`;

const Header = styled.div`
    border-bottom: 1px solid whitesmoke;
    display: flex;

    background-color: #ddf9ff;
    color: black;

    @media ${MEDIA_QUERIES.tablet}{
        height: 60px;
        font-size: 16px;
        justify-content: left;
        color: grey;
        background-color: white;
        align-items: center;
        justify-content: space-between;
    }

    @media ${MEDIA_QUERIES.laptop}{
        height: 60px;
    }
`;

const HeadingGroup = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 30px;

    @media ${MEDIA_QUERIES.tablet}{
        width: unset;
        padding-left: unset;
    }
`;

const BrandImage = styled.img`
    border-radius: 100px;
    background-color: white;
    width: 40px;
    height: 40px;
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 50px;
    justify-content: center;
    width: 100%;

    @media ${MEDIA_QUERIES.tablet}{
        width: unset;
    }
`;

const TableContainer = styled.div`
    height: ${window.innerHeight-51-10}px;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 20px;

    @media ${MEDIA_QUERIES.tablet}{
        padding-top: 20px;
        padding-bottom: unset;
        height: ${window.innerHeight - 111 -20}px;
    }

    @media ${MEDIA_QUERIES.laptop}{
        padding-top: 20px;
        padding-bottom: unset;
        height: ${window.innerHeight - 111 -20}px;
    }
`;

const mapStateToProps = state => ({
    category : state.category,
    device_settings: state.settings.device
})

class RightBar extends Component {
    render() {
        const { width, category, device_settings } = this.props;
        const { is_small_device, is_large_device } = device_settings;
        const row_height = is_small_device ? '80px': '100px';

        const columns = getColumns(device_settings);
        const category_data = CATEGORY_ITEMS[category];
        const raw_data = category_data["items"];
        const data = raw_data.filter((each_item) => {
            if (ITEMS_DATA.hasOwnProperty(each_item)){
                return true
            }
            else{
                return false
            }
        });

        return (
            <Container width={width}>
                <Header>
                    <HeadingGroup>
                        <a href="/" style={{paddingRight: "10px"}}>
                            <BrandImage src="https://i.ibb.co/M7z8qwb/favicon.png" />
                        </a>
                        <Heading>
                            <b>{category_data["name"]}</b>
                            {` : Found ${data.length} ${data.length === 1 ? 'Component' : 'Components'}` }
                        </Heading>
                    </HeadingGroup>
                    {
                        is_large_device ? <Subscribe/> : null
                    }
                </Header>
                <TableContainer>
                    <ReactTable
                        data={data}
                        columns={columns}
                        className="-striped -highlight borderless"
                        
                        minRows={data.length}
                        pageSize={data.length}
                        showPagination={false}

                        defaultSorted={[
                            {
                            id: "stars",
                            desc: true
                            }
                        ]}

                        resizable={true}

                        getTrProps={(state, rowInfo, column) => {
                            return {
                            style: {
                                    height: row_height,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }
                            }
                        }}
                    />
                </TableContainer>
            </Container>
        )
    }
}

export default connect(mapStateToProps , null)(RightBar);