const CATEGORY_ITEMS = {
  "editable_data_grid": {
    "parent_category": "UI Components",
    "description": "spreadsheet",
    "items": [
      "ceolter/ag-grid",
      "GigaTables/reactables",
      "adazzle/react-data-grid"
    ],
    "name": "Editable data grid"
  },
  "table": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "xudafeng/autoresponsive-react",
      "GriddleGriddle/Griddle",
      "komarovalexander/ka-table",
      "mbrn/material-table",
      "gregnb/mui-datatables",
      "jbetancur/react-data-table-component",
      "davidguttman/react-pivot",
      "tannerlinsley/react-table",
      "rsuite/rsuite-table",
      "sematext/sematable"
    ],
    "name": "Table"
  },
  "infinite_scroll": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "seatgeek/react-infinite",
      "jasonslyvia/react-lazyload",
      "orgsync/react-list",
      "bvaughn/react-virtualized",
      "sarons/react-recycled-scrolling"
    ],
    "name": "Infinite Scroll"
  },
  "overlay": {
    "parent_category": "UI Components",
    "description": "Display overlay / modal / alert / dialog / lightbox / popup",
    "items": [
      "yuanyan/boron",
      "upmostly/modali",
      "davidtheclark/react-aria-modal",
      "alexkuz/react-dock",
      "reactjs/react-modal",
      "marcio/react-skylight"
    ],
    "name": "Overlay"
  },
  "notification": {
    "parent_category": "UI Components",
    "description": "Toaster / snackbar — Notify the user with a modeless temporary little popup",
    "items": [
      "Cogoport/cogo-toast",
      "teodosii/react-notifications-component",
      "iamhosseindhv/notistack",
      "pburtchaell/react-notification",
      "fkhadra/react-toastify",
      "jossmac/react-toast-notifications",
      "LouisBarranqueiro/reapop",
      "joeattardi/react-snackbar-alert",
      "alexpermyakov/simple-react-notifications"
    ],
    "name": "Notification"
  },
  "tooltip": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "romainberger/react-portal-tooltip",
      "wwayne/react-tooltip"
    ],
    "name": "Tooltip"
  },
  "menu": {
    "parent_category": "UI Components",
    "description": "Menus / sidebars",
    "items": [
      "negomi/react-burger-menu",
      "vkbansal/react-contextmenu",
      "vutran/react-offcanvas",
      "balloob/react-sidebar"
    ],
    "name": "Menu"
  },
  "sticky": {
    "parent_category": "UI Components",
    "description": "Fixed headers / scroll-up headers / sticky elements",
    "items": [
      "KyleAMathews/react-headroom",
      "captivationsoftware/react-sticky",
      "yahoo/react-stickynode"
    ],
    "name": "Sticky"
  },
  "tabs": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "reactjs/react-tabs",
      "ctxhou/react-tabtab"
    ],
    "name": "Tabs"
  },
  "loader": {
    "parent_category": "UI Components",
    "description": "Loaders / spinners / progress bars — Let the user know that something is loading",
    "items": [
      "availity/react-block-ui",
      "TheCognizantFoundry/react-loader",
      "jonjaques/react-loaders",
      "tsuyoshiwada/react-md-spinner",
      "mathieudutour/react-progress-button",
      "wangzuo/react-progress-label",
      "mironov/react-redux-loading-bar",
      "KyleAMathews/react-spinkit",
      "JoshK2/react-spinners-css"
    ],
    "name": "Loader"
  },
  "carousel": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "jl-/flat-carousel",
      "rcaferati/react-awesome-slider",
      "express-labs/pure-react-carousel",
      "kidjp85/react-id-swiper",
      "leandrowd/react-responsive-carousel",
      "akiran/react-slick",
      "one89/react-carouselize"
    ],
    "name": "Carousel"
  },
  "buttons": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "rcaferati/react-awesome-button"
    ],
    "name": "Buttons"
  },
  "collapse": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "springload/react-accessible-accordion",
      "nkbt/react-collapse"
    ],
    "name": "Collapse"
  },
  "chart": {
    "parent_category": "UI Components",
    "description": "Display data in charts / graphs / diagrams",
    "items": [
      "kirillstepkin/chartify",
      "syncfusion/ej2-react-ui-components/tree/master/components/charts",
      "fraserxu/react-chartist",
      "99ff00/react-charty",
      "jerairrest/react-chartjs-2",
      "codesuki/react-d3-components",
      "Raathigesh/Dazzle",
      "RakanNimer/react-google-charts",
      "kirjs/react-highcharts",
      "dunnock/react-sigma",
      "KyleAMathews/react-sparkline",
      "borisyankov/react-sparklines",
      "esnet/react-timeseries-charts",
      "unsplash/react-trend",
      "uber/react-vis",
      "recharts/recharts",
      "rumble-charts/rumble-charts",
      "FormidableLabs/victory",
      "jask-oss/reaviz"
    ],
    "name": "Chart"
  },
  "tree": {
    "parent_category": "UI Components",
    "description": "Display a tree data structure",
    "items": [
      "alexcurtis/react-treebeard",
      "chenglou/react-treeview",
      "pqx/react-ui-tree"
    ],
    "name": "Tree"
  },
  "ui_navigation": {
    "parent_category": "UI Components",
    "description": "Ways to navigate views",
    "items": [
      "fisshy/react-scroll",
      "oliviertassinari/react-swipeable-views"
    ],
    "name": "UI Navigation"
  },
  "custom_scrollbar": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "rommguy/react-custom-scroll",
      "souhe/reactScrollbar",
      "andrelmlins/react-shadow-scroll"
    ],
    "name": "Custom Scrollbar"
  },
  "audio___video": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "u-wave/react-dailymotion",
      "FormidableLabs/react-music",
      "CookPete/react-player",
      "soundblogs/react-soundplayer",
      "troybetz/react-youtube",
      "video-react/video-react"
    ],
    "name": "Audio / Video"
  },
  "map": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "istarkov/google-map-react",
      "ubilabs/react-geosuggest",
      "MicheleBertoli/react-gmaps",
      "PaulLeCam/react-leaflet",
      "uber/react-map-gl",
      "alex3165/react-mapbox-gl"
    ],
    "name": "Map"
  },
  "time___date___age": {
    "parent_category": "UI Components",
    "description": "Display time / date / age",
    "items": [
      "nmn/react-timeago",
      "hustcc/timeago-react"
    ],
    "name": "Time / Date / Age"
  },
  "photo___image": {
    "parent_category": "UI Components",
    "description": "Display images / photos",
    "items": [
      "junkboy0315/react-compare-image",
      "xiaolin/react-image-gallery",
      "fritz-c/react-image-lightbox",
      "jossmac/react-images",
      "brycedorn/react-intense",
      "neptunian/react-photo-gallery",
      "chrvadala/react-svg-pan-zoom"
    ],
    "name": "Photo / Image"
  },
  "paginator": {
    "parent_category": "UI Components",
    "description": "Display a control element to paginate",
    "items": [
      "AdeleD/react-paginate",
      "lionix-team/react-laravel-paginex"
    ],
    "name": "Paginator"
  },
  "markdown_viewer": {
    "parent_category": "UI Components",
    "description": "Display parsed markdow source",
    "items": [
      "rexxars/react-markdown"
    ],
    "name": "Markdown Viewer"
  },
  "miscellaneous_-_ui_components": {
    "parent_category": "UI Components",
    "description": "",
    "items": [
      "BelkaLab/material-color-hash",
      "Sitebase/react-avatar",
      "JosephSmith127/react-avatar-generator",
      "ukrbublik/react-awesome-query-builder",
      "javierbyte/react-blur",
      "CherryProjects/react-facebook",
      "LucasBassetti/react-simple-chatbot",
      "ngokevin/react-file-reader-input",
      "komarovalexander/react-filter-control",
      "gilbarbara/react-joyride",
      "alexkuz/react-json-tree",
      "bokuweb/react-resizable-and-movable",
      "bokuweb/react-resizable-box",
      "tomkp/react-split-pane",
      "arnaudambro/react-swipe-to-delete-ios",
      "KyleAMathews/typography.js"
    ],
    "name": "Miscellaneous - UI Components"
  },
  "date___time_picker": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Date picker / time picker / datetime picker / date range picker",
    "items": [
      "intljusticemission/react-big-calendar",
      "freiksenet/react-calendar",
      "Adphorus/react-date-range",
      "Hacker0x01/react-datepicker",
      "mberneti/react-datepicker2",
      "YouCanBookMe/react-datetime",
      "gpbl/react-day-picker",
      "coderhaoxin/react-flatpickr",
      "hernansartorio/react-nice-dates",
      "antonfisher/react-simple-timefield",
      "ndom91/react-timezone-select",
      "BelkaLab/react-yearly-calendar",
      "KELEN/r-date-picker"
    ],
    "name": "Date / Time picker"
  },
  "input_types": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Masked inputs, specialized inputs; email / telephone number / credit card / etc.",
    "items": [
      "amarofashion/react-credit-cards",
      "medipass/react-payment-inputs",
      "sanniassin/react-input-mask",
      "insin/react-maskedinput",
      "msafi/text-mask",
      "gpietro/react-numpad"
    ],
    "name": "Input Types"
  },
  "autocomplete": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Autosuggest / autocomplete / typeahead",
    "items": [
      "moroshko/react-autosuggest",
      "fmoo/react-typeahead"
    ],
    "name": "Autocomplete"
  },
  "select": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "davidtheclark/react-aria-menubutton",
      "instructure-react/react-select-box",
      "JedWatson/react-select",
      "furqanZafar/react-selectize"
    ],
    "name": "Select"
  },
  "color_picker": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "elrumordelaluz/coloreact",
      "casesandberg/react-color",
      "wangzuo/react-input-color"
    ],
    "name": "Color Picker"
  },
  "toggle": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "clari/react-ios-switch",
      "instructure-react/react-toggle",
      "geobde/react-triple-toggle"
    ],
    "name": "Toggle"
  },
  "radio_button": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "chenglou/react-radio-group"
    ],
    "name": "Radio Button"
  },
  "type_select": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Let the user select something (e.g. a tag while typing",
    "items": [
      "yury-dymov/react-autocomplete-input",
      "effektif/react-mentions"
    ],
    "name": "Type Select"
  },
  "tag_input": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Let the user add multiple tags in a single input",
    "items": [
      "pathofdev/react-tag-input",
      "prakhar1989/react-tags",
      "olahol/react-tagsinput",
      "instructure-react/react-tokeninput"
    ],
    "name": "Tag Input"
  },
  "autosize_input___textarea": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "JedWatson/react-input-autosize",
      "andreypopp/react-textarea-autosize"
    ],
    "name": "Autosize Input / Textarea"
  },
  "star_rating": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "fedoryakubovich/react-awesome-stars-rating",
      "ikr/react-star-rating-input",
      "cameronroe/react-star-rating"
    ],
    "name": "Star Rating"
  },
  "drag_and_drop": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "atlassian/react-beautiful-dnd",
      "gaearon/react-dnd",
      "mzabriskie/react-draggable",
      "bevacqua/react-dragula",
      "okonet/react-dropzone",
      "bokuweb/react-sortable-pane"
    ],
    "name": "Drag and Drop"
  },
  "sortable_list": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Let the user define an order on a list",
    "items": [
      "jasonslyvia/react-anything-sortable",
      "clauderic/react-sortable-hoc",
      "danielstocks/react-sortable",
      "SortableJS/Sortable"
    ],
    "name": "Sortable List"
  },
  "rich_text_editor": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "liferay/alloy-editor",
      "facebook/draft-js",
      "globocom/megadraft",
      "securingsincity/react-ace",
      "JedWatson/react-codemirror",
      "lovasoa/react-contenteditable",
      "jpuri/react-draft-wysiwyg",
      "wangzuo/react-medium-editor",
      "zenoamaro/react-quill",
      "RD17/react-trumbowyg",
      "ritzyed/ritzy"
    ],
    "name": "Rich Text Editor"
  },
  "markdown_editor": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "jrm2k6/react-markdown-editor",
      "JedWatson/react-md-editor"
    ],
    "name": "Markdown Editor"
  },
  "image_editing": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "Image manipulation",
    "items": [
      "DropsOfSerenity/react-avatar-cropper",
      "mosch/react-avatar-editor",
      "ricardo-ch/react-easy-crop",
      "DominicTobias/react-image-crop",
      "jerryshew/react-image-cropper"
    ],
    "name": "Image Editing"
  },
  "form_component_collections": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "mbrookes/formsy-material-ui",
      "twisty/formsy-react-components",
      "alexkuz/react-input-enhancements",
      "jquense/react-widgets"
    ],
    "name": "Form Component Collections"
  },
  "miscellaneous_-_form_components": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "milesj/interweave",
      "fatiherikli/react-designer",
      "TPMinan/react-upload-gallery"
    ],
    "name": "Miscellaneous - Form Components"
  },
  "syntax_highlight": {
    "grand_parent_category": "UI Components",
    "parent_category": "Form Components",
    "description": "",
    "items": [
      "conorhastings/react-syntax-highlighter"
    ],
    "name": "Syntax Highlight"
  },
  "ui_layout": {
    "description": "Components to layout the app's UI",
    "items": [
      "xudafeng/autoresponsive-react",
      "nachoaIvarez/flexbox-react",
      "deepstreamIO/golden-layout",
      "JSBros/hedron",
      "martinnov92/React-Splitters",
      "Paol-imi/muuri-react",
      "roylee0704/react-flexbox-grid",
      "STRML/react-grid-layout",
      "eiriklv/react-masonry-component",
      "leefsmp/Re-Flex",
      "aeagle/react-spaces",
      "tsuyoshiwada/react-stack-grid",
      "dantrain/react-stonecutter"
    ],
    "name": "UI Layout"
  },
  "ui_animation": {
    "description": "Animate transitions",
    "items": [
      "tkh44/data-driven-motion",
      "stelatech/react-anime",
      "joshwcomeau/react-flip-move",
      "azazdeaz/react-gsap-enhancer",
      "ryanflorence/react-magic-move",
      "souporserious/react-motion-ui-pack",
      "chenglou/react-motion",
      "moarwick/react-mt-svg-lines",
      "maisano/react-router-transition",
      "react-spring/react-spring",
      "gilbox/react-spark-scroll",
      "gilbox/react-track",
      "Lapple/react-transitive-number",
      "clari/react-tween",
      "bringking/react-web-animation",
      "twitter-fabric/velocity-react",
      "giladk/react-scroll-rotate",
      "lindelof/particles-bg"
    ],
    "name": "UI Animation"
  },
  "parallax": {
    "parent_category": "UI Animation",
    "description": "",
    "items": [
      "keske/react-parallax-component",
      "mkosir/react-parallax-tilt"
    ],
    "name": "Parallax"
  },
  "responsive": {
    "parent_category": "UI Frameworks",
    "description": "Set of components + responsive layout system",
    "items": [
      "ant-design/ant-design",
      "https://atlaskit.atlassian.com/packages",
      "https://baseweb.design",
      "nikgraf/belle",
      "carbon-design-system/carbon",
      "chakra-ui/chakra-ui",
      "segmentio/evergreen",
      "fluent-org/fluent-ui",
      "grommet/grommet",
      "pinterest/gestalt",
      "OfficeDev/office-ui-fabric-react",
      "pivotal-cf/pivotal-ui",
      "react-bootstrap/react-bootstrap",
      "akiran/react-foundation-apps",
      "nordsoftware/react-foundation",
      "reakit/reakit",
      "jxnblk/rebass",
      "searchkit/searchkit",
      "Semantic-Org/Semantic-UI-React",
      "sheinsight/shineout"
    ],
    "name": "Responsive"
  },
  "material_design": {
    "parent_category": "UI Frameworks",
    "description": "",
    "items": [
      "mui-org/material-ui",
      "https://material-ui.com/components/tree-view/",
      "https://material-ui.com/components/material-icons/",
      "https://material-ui.com/components/modal/",
      "https://material-ui.com/components/slider/",
      "https://material-ui.com/components/tables/",
      "https://material-ui.com/components/tree-view/",
      "Evo-Forge/Essence",
      "react-materialize/react-materialize",
      "react-toolbox/react-toolbox"
    ],
    "name": "Material Design"
  },
  "component_collections": {
    "parent_category": "UI Frameworks",
    "description": "",
    "items": [
      "palantir/blueprint",
      "dataminr/react-components",
      "DesignRevision/shards-react",
      "ngokevin/aframe-react",
      "marmelab/react-admin",
      "gabrielbull/react-desktop",
      "yoloten/react-stack-cards",
      "rsuite/rsuite"
    ],
    "name": "Component Collections"
  },
  "visibility_reporter": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Reporter : _Report computed styles_",
    "description": "Report when a component becomes visible/hidden",
    "items": [
      "joshwnj/react-visibility-sensor",
      "brigade/react-waypoint"
    ],
    "name": "Visibility Reporter"
  },
  "measurement_reporter": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Reporter : _Report computed styles_",
    "description": "Determine and report measurements of an element",
    "items": [
      "ctrlplusb/react-component-queries",
      "okonet/react-container-dimensions",
      "digidem/react-dimensions",
      "nkbt/react-height",
      "souporserious/react-measure",
      "ctrlplusb/react-sizeme"
    ],
    "name": "Measurement Reporter"
  },
  "keyboard_events": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Device Input : _Turn user input into actions_",
    "description": "",
    "items": [
      "chrisui/react-hotkeys",
      "ayrton/react-key-handler",
      "glortho/react-keydown",
      "avocode/react-shortcuts"
    ],
    "name": "Keyboard Events"
  },
  "scroll_events": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Device Input : _Turn user input into actions_",
    "description": "",
    "items": [
      "jeroencoumans/react-scroll-components"
    ],
    "name": "Scroll Events"
  },
  "touch_swipe": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Device Input : _Turn user input into actions_",
    "description": "",
    "items": [
      "voronianski/react-swipe"
    ],
    "name": "Touch Swipe"
  },
  "mouse_events": {
    "grand_parent_category": "UI Utilities",
    "parent_category": "Device Input : _Turn user input into actions_",
    "description": "",
    "items": [
      "gabrielbull/react-aim",
      "mkosir/react-hook-mighty-mouse"
    ],
    "name": "Mouse Events"
  },
  "meta_tags": {
    "parent_category": "UI Utilities",
    "description": "Set meta tags, <title>, children of <head>",
    "items": [
      "kodyl/react-document-meta",
      "gaearon/react-document-title",
      "nfl/react-helmet"
    ],
    "name": "Meta Tags"
  },
  "portal": {
    "parent_category": "UI Utilities",
    "description": "Render an element at an arbitrary DOM node",
    "items": [
      "cloudflare/react-gateway",
      "fckt/react-layer-stack",
      "tajo/react-portal"
    ],
    "name": "Portal"
  },
  "test_user_behavior": {
    "parent_category": "UI Utilities",
    "description": "A/B tests, experiments, ...",
    "items": [
      "olahol/react-ab",
      "HubSpot/react-experiments"
    ],
    "name": "Test User Behavior"
  },
  "data_store": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "Data flow / data management / data stores / components state / data flow",
    "items": [
      "goatslacker/alt",
      "Yomguithereal/baobab-react",
      "cerebral/cerebral",
      "philpl/fluorine",
      "yahoo/fluxible",
      "BinaryMuse/fluxxor",
      "mariusandra/kea",
      "mobxjs/mobx-react",
      "smakazmi/react-3ducks",
      "matthewwithanm/react-controllables",
      "yahoo/react-i13n",
      "reactjs/react-redux",
      "yandavid/react-storage-hoc",
      "tshelburne/redux-batched-actions",
      "tappleby/redux-batched-subscribe",
      "reactjs/redux",
      "reflux/refluxjs",
      "reactjs/reselect",
      "SiftScience/resourcerer",
      "shastajs/shasta"
    ],
    "name": "Data Store"
  },
  "form_logic": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "guilouro/formcat",
      "jaredpalmer/formik",
      "formsy/formsy-react/",
      "AppliedMathematicsANU/plexus-form",
      "react-hook-form/react-hook-form",
      "mozilla-services/react-jsonschema-form",
      "jurassix/react-validation-mixin",
      "final-form/react-final-form",
      "MAKARD/react-formawesome",
      "surveyjs/survey-library",
      "gcanti/tcomb-form",
      "andrewhathaway/Winterfell"
    ],
    "name": "Form Logic"
  },
  "router": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "cerebral/cerebral-module-router",
      "matthewwithanm/monorouter",
      "svenanders/react-breadcrumbs",
      "STRML/react-router-component",
      "taion/react-router-scroll",
      "reactjs/react-router",
      "acdlite/redux-router",
      "kriasoft/universal-router",
      "salvoravida/redux-first-history"
    ],
    "name": "Router"
  },
  "props_from_server": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "Component properties asynchronously fetched over the network",
    "items": [
      "andreypopp/react-async",
      "heroku/react-refetch",
      "ericclemmons/react-resolver",
      "relay-tools/react-router-relay",
      "markdalgleish/redial",
      "Rezonans/redux-async-connect",
      "makeomatic/redux-connect"
    ],
    "name": "Props from server"
  },
  "communication_with_server": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "gyzerok/adrenaline",
      "apollostack/apollo-client",
      "cerebral/cerebral-module-http",
      "apollostack/react-apollo",
      "facebook/relay",
      "RickWong/react-transmit"
    ],
    "name": "Communication with server"
  },
  "css___style": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "milesj/aesthetic ",
      "Khan/aphrodite",
      "JedWatson/classnames",
      "rofrischmann/inline-style-prefixer",
      "FormidableLabs/radium",
      "d6u/react-container-query",
      "gajus/react-css-modules",
      "contra/react-responsive",
      "jmlweb/reactponsive",
      "styled-components/styled-components"
    ],
    "name": "CSS / Style"
  },
  "html_template": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "substack/hyperx",
      "AlexGilleran/jsx-control-statements",
      "wix/react-templates"
    ],
    "name": "HTML Template"
  },
  "isomorphic_apps": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "airbnb/hypernova",
      "denvned/isomorphic-relay",
      "kriasoft/isomorphic-style-loader",
      "redfin/react-server",
      "rill-js/rill",
      "halt-hammerzeit/webpack-isomorphic-tools"
    ],
    "name": "Isomorphic Apps"
  },
  "boilerplate": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "Scaffold / starter kit / Yeoman generator / stack ensemble / seed",
    "items": [
      "facebookincubator/create-react-app",
      "chentsulin/electron-react-boilerplate",
      "pheuter/essential-react",
      "alexfedoseev/generator-flux-on-rails",
      "react-webpack-generators/generator-react-webpack",
      "FredericHeem/starhackit",
      "TrueCar/gluestick",
      "insin/nwb",
      "mxstbr/react-boilerplate",
      "gaearon/react-hot-boilerplate",
      "erikras/react-redux-universal-hot-example",
      "reactuate/reactuate",
      "SpencerCDixon/redux-cli",
      "lvarayut/relay-fullstack",
      "rocjs/roc",
      "bdefore/universal-redux",
      "andrelmlins/create-react-dependency"
    ],
    "name": "Boilerplate"
  },
  "miscellaneous_-_code_design": {
    "parent_category": "Code Design : _Libraries that help with code design_",
    "description": "",
    "items": [
      "matthewwithanm/react-inlinesvg",
      "lynndylanhurley/redux-auth",
      "treasure-data/redux-search",
      "gcanti/tcomb-react",
      "geobde/react-find"
    ],
    "name": "Miscellaneous - Code Design"
  },
  "utilities": {
    "description": "",
    "items": [
      "zpao/qrcode.react",
      "fernandopasik/react-children-utilities",
      "Olical/react-faux-dom",
      "ReactTraining/react-media",
      "bluepeter/react-middle-ellipsis",
      "martinandert/react-translate-component"
    ],
    "name": "Utilities"
  },
  "i18n": {
    "parent_category": "Utilities",
    "description": "Internationalization / L10n / localization / translation",
    "items": [
      "i18next/react-i18next",
      "yahoo/react-intl",
      "CherryProjects/react-translate-maker",
      "alibaba/react-intl-universal",
      "lingui/js-lingui"
    ],
    "name": "i18n"
  },
  "framework_bindings___integrations": {
    "parent_category": "Utilities",
    "description": "",
    "items": [
      "magalhas/backbone-react-component",
      "KtorZ/elm-react-component",
      "ProjectSeptemberInc/gl-react",
      "jhudson8/react-backbone",
      "react-d3-library/react-d3-library",
      "evancz/react-elm-components",
      "pilwon/react-famous",
      "STRML/react-localstorage",
      "shakacode/react_on_rails",
      "syranide/react-swf",
      "toxicFork/react-three-renderer",
      "firebase/reactfire",
      "PixelsCommander/ReactiveElements"
    ],
    "name": "Framework bindings / integrations"
  },
  "integrations_with_third_party_services": {
    "parent_category": "Utilities",
    "description": "",
    "items": [
      "react-ga/react-ga",
      "hzdg/react-google-analytics",
      "appleboy/react-recaptcha",
      "azmenak/react-stripe-checkout",
      "rangle/redux-segment"
    ],
    "name": "Integrations with Third Party Services"
  },
  "ui": {
    "parent_category": "Performance",
    "description": "",
    "items": [
      "trueadm/inferno",
      "Flipboard/react-canvas",
      "JakeSidSmith/react-fastclick",
      "reactjs/react-static-container"
    ],
    "name": "UI"
  },
  "inspect_-_ui": {
    "parent_category": "UI",
    "description": "",
    "grand_parent_category": "Performance",
    "items": [
      "RamonGebben/react-perf-tool",
      "redsunsoft/react-render-visualizer",
      "garbles/why-did-you-update"
    ],
    "name": "Inspect - UI"
  },
  "lazy_load": {
    "parent_category": "UI",
    "description": "",
    "grand_parent_category": "Performance",
    "items": [
      "ggordan/react-infinite-grid",
      "seatgeek/react-infinite",
      "loktar00/react-lazy-load",
      "jasonslyvia/react-lazyload",
      "bvaughn/react-virtualized"
    ],
    "name": "Lazy Load"
  },
  "app_size": {
    "parent_category": "Performance",
    "description": "",
    "items": [
      "oliviertassinari/babel-plugin-transform-react-remove-prop-types",
      "Lucifier129/react-lite"
    ],
    "name": "App Size"
  },
  "server-side_rendering": {
    "parent_category": "Performance",
    "description": "",
    "items": [
      "dunglas/react-esi"
    ],
    "name": "Server-Side Rendering"
  },
  "test": {
    "parent_category": "Dev Tools",
    "description": "",
    "items": [
      "carteb/carte-blanche",
      "producthunt/chai-enzyme",
      "airbnb/enzyme",
      "facebook/jest",
      "pzavolinsky/react-unit",
      "sotojuan/redux-ava",
      "conorhastings/redux-test-recorder",
      "milesj/rut",
      "philcockfield/ui-harness",
      "bruderstein/unexpected-react"
    ],
    "name": "Test"
  },
  "redux": {
    "parent_category": "Dev Tools",
    "description": "",
    "items": [
      "romseguy/redux-devtools-chart-monitor",
      "gaearon/redux-devtools-dock-monitor",
      "bvaughn/redux-devtools-filterable-log-monitor",
      "alexkuz/redux-devtools-inspector",
      "gaearon/redux-devtools-log-monitor",
      "gaearon/redux-devtools",
      "zalmoxisus/remote-redux-devtools"
    ],
    "name": "Redux"
  },
  "inspect_-_dev_tools": {
    "parent_category": "Dev Tools",
    "description": "",
    "items": [
      "xyc/react-inspector",
      "Lapple/react-json-inspector",
      "reactotron/reactotron"
    ],
    "name": "Inspect - Dev Tools"
  },
  "miscellaneous": {
    "description": "",
    "items": [
      "roman01la/html-to-react-components",
      "reactjs/react-magic",
      "repetere/jsonx",
      "plouc/mozaik",
      "Yomguithereal/react-blessed"
    ],
    "name": "Miscellaneous"
  }
}

export default CATEGORY_ITEMS