import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

// reducers
import category from './category.ts';
import settings from './settings.ts';

const reducer = (history) => combineReducers({
    category,
    settings,
    router: connectRouter(history)
})

export default reducer;