import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import MessageIcon from './MessageIcon.js';
import { MEDIA_QUERIES } from './Constants/device.js';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Icon } from '@iconify/react';
import checkMarkButton from '@iconify/icons-emojione-v1/check-mark-button';
import sharpError from '@iconify/icons-ic/sharp-error';
import loaderIcon from '@iconify/icons-feather/loader';

const Container = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;

    @media ${MEDIA_QUERIES.laptop}{
        position: static; 
        align-items: center;
        width: unset;
    }
`;

const EmailContainer = styled.div`
    background-color: #F1F5F8;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    flex-grow: 2;

    @media ${MEDIA_QUERIES.laptop}{
        padding: 10px 10px;
        margin-right: 25px;
        border-radius: 5px;
        flex-grow: unset;
    }
`;

const EmailInput = styled.input`
    border: none;
    outline: none;
    background-color: #F1F5F8;
    font-size: 13px;
    width: 100%;

    @media ${MEDIA_QUERIES.laptop}{
        width: 290px;
        font-size: 15px;
    }
`;

const SubscribeButton = styled.button`
    background-color: #b4a2e4;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;

    font-size: 13px;
    padding: 12px 8px;

    @media ${MEDIA_QUERIES.laptop}{
        font-size: 16px;
        padding: 10px 16px;
        border-radius: 5px;
    }
`;

const mapStateToProps = state => ({
    device_settings: state.settings.device
});

const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });
  
    return (
      <Container>
        <EmailContainer>
            <MessageIcon /> 
            <EmailInput 
                ref={node => (email = node)}
                type="email"
                placeholder="Get weekly updates of latest Components" 
            />
            {status === "sending" && <div>
                <Icon icon={loaderIcon} />
            </div>}
            {status === "error" && (
            
            <div style={{ color: "red" }}>
                <Icon icon={sharpError} />
            </div>
            )}

            {status === "success" && (
            <div>
                <Icon icon={checkMarkButton} />
            </div>
            )}
        </EmailContainer>
        <SubscribeButton onClick={submit}>Stay Updated</SubscribeButton>
      </Container>
    );
  };

class Subscribe extends Component {
    render() {
        const url = "https://loop8.us19.list-manage.com/subscribe/post?u=e87291727b2d5cfea63ed1687&amp;id=a798a96fdf";

        return (
            <Container>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </Container>
        )
    }
}

export default connect(mapStateToProps , null)(Subscribe);