import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import LeftBar from './LeftBar.tsx';
import RightBar from './RightBar.js';
import { SET_CATEGORY } from './Constants/actionTypes';

const mapStateToProps = state => ({
  device_settings: state.settings.device,
  category: state.category
})

const mapDispatchToProps = dispatch => ({
  setCategory : (payload) => dispatch({ type: SET_CATEGORY , payload })
});

const Container = styled.div`
  width: 100%;
`;

const Body = styled.div`
  display: flex;
`;

class Explorer extends Component {

  constructor(props){
    super(props);

    const category_id = this.props.match.params.category_id;
    this.props.setCategory(category_id);
  }

  findWidth = () => {
    const { is_small_device, is_medium_device, is_large_device } = this.props.device_settings;
    let leftbar_width;
    let rightbar_width;

    if (is_small_device){
      leftbar_width = '0%';
      rightbar_width = '100%';
    }
    else if (is_medium_device){
      leftbar_width = '20%';
      rightbar_width = '75%';
    }
    else if (is_large_device){
      leftbar_width = '15%';
      rightbar_width = '80%';
    }

    return { leftbar_width, rightbar_width }
  }

  render() {
    const { leftbar_width, rightbar_width } = this.findWidth();
    return (
      <Container>
          <Body>
            {
              leftbar_width !== "0%" ? <LeftBar width={leftbar_width} /> : null
            }
            <RightBar width={rightbar_width} />
          </Body>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);