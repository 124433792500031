import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import reducer from './Reducers/reducerIndex';

const getMiddlewares = (history) => {
  const RouterMiddleware = routerMiddleware(history);

  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(RouterMiddleware);
  }
  else {
    return applyMiddleware(RouterMiddleware, createLogger());
  }  
};

const history = createBrowserHistory();
const store = createStore(reducer(history), compose(getMiddlewares(history)));
export { history, store };